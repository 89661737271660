
import Vue from "vue"
import Webcam from "@/plugins/webcam"

export default Vue.extend({
  data: () => ({
    show: false as boolean,
    showWebcamInfo: false as boolean,
    webcam: null as Webcam | null,
    currentFacingMode: null as string | null,
    webcamCount: 0 as number | undefined, //undefined when trying to access property before object has been constructed
    error: false as boolean,
    snapped: false as boolean,
    sendSnap: false as boolean,
    snapshot: null as string | Blob | null,
    flipping: false as boolean // true when camera is flipping, prevent multiple button clicks
  }),
  props: {
    facingMode: { // default facing mode tried to be use when opening the webcam
      type: String,
      default: 'user',
      validator(value: string) {
        return ['user', 'environment'].includes(value)
      }
    }
  },
  computed: {
    webcamGuide: function() {
      if (this.currentFacingMode === 'user' || this.currentFacingMode === null) {
        return this.$vuetify.lang.t('$vuetify.subscription_page.portrait_guide')
      } else {
        return this.$vuetify.lang.t('$vuetify.subscription_page.scanner_guide')
      }
    },
    infoWebcamLabel: function() {
      return this.webcam?.webcamList.filter(webcam => webcam.deviceId === this.webcam?.selectedDeviceId)[0].label
    }
  },
  methods: {
    close() {
      this.webcam?.stop()
      this.show = false
      this.showWebcamInfo = false
      this.snapped = false
      this.snapshot = null
    },
    async openWebcam(title: string) {
      this.webcam = new Webcam(this.$refs.webcam as HTMLVideoElement, this.facingMode, this.$refs.canvas as HTMLCanvasElement, this.$refs.snapSound as HTMLAudioElement)
      this.show = true
      await this.webcam.start()
        .then((facingMode: string) => {
          this.currentFacingMode = facingMode
          this.webcamCount = this.webcam?.webcamCount
          this.error = false
        })
        .catch((error: string) => {
          this.error = true
          console.log(error)
        })
        // NOTE - when used on MultipleUploadCard.vue, dialog isn't shown with the first "this.show = true" l.120
        .finally(() => {
          this.show = true
        })
    },
    async cameraFlip() {
      if (this.flipping) {
        return false
      }

      this.flipping = true
      await this.webcam?.flip()
        .then((facingMode: string) => {
          this.currentFacingMode = facingMode
        })
        .catch((error: string) => {
          this.error = true
          console.log(error)
        })
        .finally(() => {
          this.flipping = false
        })
      return true
    },
    async snap() {
      this.snapped = true
      await this.webcam?.snap()
        .then((snapshot: string | Blob | null) => {
          this.snapshot = snapshot
        })
        .catch((error) => {
          console.log(error)
        })
    },
    rejectSnap() {
      this.snapshot = null
      this.snapped = false
      this.webcam?.resume()
    },
    uploadSnap() {
      this.$emit("uploadSnapshot", this.snapshot)
      this.close()
    },
    toggleWebcamInfo() {
      this.showWebcamInfo = !this.showWebcamInfo
    },
    setWebcamConfiguration(facingMode: string, deviceLabel: string) {
      localStorage.setItem(facingMode + 'WebcamLabel', deviceLabel)
    },
    clearWebcamConfiguration() {
      localStorage.removeItem('userWebcamLabel')
      localStorage.removeItem('environmentWebcamLabel')
    }
  }
})
