// NOTE - this is a tweaked version of https://github.com/bensonruan/webcam-easy
// modified to our needs, it contains the following modifications:
//  * webcam flip is working even if webcam aren't tagged with label "front"/"back" => works on desktop
//  * webcam flip now include the call to start function
//  * webcam flip now works for N cameras
//  * snap() function is now returning a JPEG Blob with a quality of 80%
//  * videoConstraints will search for 4K resolution first then lower the resolution to the closest available, frameRate will try from 60 down to 15
//  * when changing webcam, we set the retained video settings "width" & "height" to our <video> tag. It will determine our snapshot resolution
// 
export default class Webcam {
    _webcamElement: HTMLVideoElement
    _canvasElement: HTMLCanvasElement | null
    _snapSoundElement: HTMLAudioElement | null
    _facingMode: string
    _streamList: MediaStream[]
    _webcamList: MediaDeviceInfo[]
    _selectedDeviceId: string

    constructor(webcamElement: HTMLVideoElement, facingMode = 'user', canvasElement: HTMLCanvasElement | null = null, snapSoundElement: HTMLAudioElement | null = null) {
      this._webcamElement = webcamElement
      this._facingMode = facingMode
      this._webcamList = []
      this._streamList = []
      this._selectedDeviceId = ''
      this._canvasElement = canvasElement
      this._snapSoundElement = snapSoundElement
      this.resume()
    }

    get facingMode(): string {
      return this._facingMode
    }

    set facingMode(value: string) {
      this._facingMode = value
    }

    get webcamList(): MediaDeviceInfo[] {
      return this._webcamList
    }

    get webcamCount(): number {
      return this._webcamList.length
    }

    get selectedDeviceId(): string {
      return this._selectedDeviceId
    }

    /* Get all video input devices info */
    getVideoInputs(mediaDevices: MediaDeviceInfo[]): MediaDeviceInfo[] {
      this._webcamList = []
      mediaDevices.forEach(mediaDevice => {
        if (mediaDevice.kind === 'videoinput') {
          this._webcamList.push(mediaDevice)
        }
      })
      if (this._webcamList.length == 1) {
        this._facingMode = 'user'
      }    
      return this._webcamList
    }

    /* Get media constraints */
    getMediaConstraints(): MediaStreamConstraints {
      const videoConstraints: MediaTrackConstraints = {
        width: { min: 640, ideal: 3840, max: 3840 },
        height: { min: 480, ideal: 2160, max: 2160 },
        frameRate: { min: 15, ideal: 60, max: 60 }
      }

      if (this._selectedDeviceId == '') {
        videoConstraints.facingMode =  this._facingMode
      } else {
        videoConstraints.deviceId = { exact: this._selectedDeviceId }
      }

      return {
        video: videoConstraints,
        audio: false
      }
    }

    /* Select camera based on facingMode */ 
    selectCamera(): void {
      const currentIndex = this._webcamList.findIndex((webcam) => webcam.deviceId == this._selectedDeviceId) // NOTE - on first init currentIndex = -1
      const nextIndex = (currentIndex + 1) % this.webcamCount
      this._selectedDeviceId = this._webcamList[nextIndex].deviceId
    }

    /* Change Facing mode and selected camera */ 
    flip(): Promise<string> {
      this._facingMode = (this._facingMode == 'user') ? 'environment': 'user' 
      this._webcamElement.style.transform = ""
      this.selectCamera()
      return this.start()
    }

    /*
      1. Get permission from user
      2. Get all video input devices info
      3. Select camera based on facingMode 
      4. Start stream
    */
    async start(startStream = true): Promise<string> {
      return new Promise((resolve, reject) => {
        this.stop()
        navigator.mediaDevices.getUserMedia(this.getMediaConstraints()) // get permissions from user
          .then(stream => {
            this._streamList.push(stream)
            this.info() // get all video input devices info
              .then(() => {
                if (this._selectedDeviceId == '') { // first initialisation
                  // check if configured webcam exists in localStorage AND can be setted from webcamList
                  if (localStorage.getItem(this._facingMode + 'WebcamLabel') !== null) {
                    this._webcamList.forEach(webcam => {
                      if (webcam.label === localStorage.getItem(this._facingMode + 'WebcamLabel')) {
                        this._selectedDeviceId = webcam.deviceId
                      }
                    })
                  }
                  // if no valid configuration detected from localStorage
                  if (this._selectedDeviceId == '') {
                    // select camera based on facingMode
                    if (this._facingMode === 'user') { // first camera is the user one
                      this._selectedDeviceId = this._webcamList[0].deviceId
                    } else { // last camera is the environment one
                      this._selectedDeviceId = this._webcamList[this.webcamCount - 1].deviceId
                    }
                  }
                }
                if (startStream) {
                  this.stream()
                    .then(facingMode => {
                      resolve(facingMode)
                    })
                    .catch(error => {
                      reject(error)
                    })
                } else {
                  resolve(this._selectedDeviceId)
                }
              })
              .catch(error => {
                reject(error)
              })
          })
          .catch(error => {
            reject(error)
          })
      })
    }

    /* Get all video input devices info */ 
    async info(): Promise<MediaDeviceInfo[]> {
      return new Promise((resolve, reject) => {
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            this.getVideoInputs(devices)
            resolve(this._webcamList)
          }) 
          .catch(error => {
            reject(error)
          })
      })
    }

    /* Start streaming webcam to video element */ 
    async stream(): Promise<string> {
      return new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia(this.getMediaConstraints())
          .then(stream => {
            this._streamList.push(stream)
            this._webcamElement.srcObject = stream
            // resize canvas to take snapshot on the same resolution as the webcam
            const settings = stream.getVideoTracks()[0].getSettings()
            this._webcamElement.height = settings.height || 1920
            this._webcamElement.width = settings.width || 1080
            this._webcamElement.play()
            resolve(this._facingMode)
          })
          .catch(error => {
            reject(error)
          })
      })
    }

    /* Stop streaming webcam */
    stop(): void {
      this._streamList.forEach(stream => {
        stream.getTracks().forEach(track => {
          track.stop()
        })
      })
      this._streamList = []
    }

    getCanvasBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
      return new Promise(function(resolve) {
        canvas.toBlob((blob) => {
          resolve(blob)
        }, 'image/jpeg', 0.8) // NOTE - more on JPEG compression at https://sirv.com/help/articles/jpeg-quality-comparison/
      })
    }

    snap(): Promise<Blob | string | null> {
      if (this._canvasElement != null) {
        if (this._snapSoundElement != null) {
          this._snapSoundElement.play()
        }
        this._canvasElement.height = this._webcamElement.height
        this._canvasElement.width = this._webcamElement.width
        const context = this._canvasElement.getContext('2d')
        context?.clearRect(0, 0, this._canvasElement.width, this._canvasElement.height)
        context?.drawImage(this._webcamElement, 0, 0, this._canvasElement.width, this._canvasElement.height)
        this._canvasElement.classList.remove("d-none")
        this._webcamElement.classList.add("d-none")
        return this.getCanvasBlob(this._canvasElement)
      } else {
        return Promise.reject("canvas element is missing")
      }
    }

    resume(): void {
      this._canvasElement?.classList.add("d-none")
      this._webcamElement.classList.remove("d-none")
    }
}
