
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"

export default Vue.extend({
    name: "SubscriptionMixin",
    data: () => ({
        agencies: [],
        logo: require("@/assets/logos/dispojob_label.svg"),
        step: 1,
        stepTitle: "",
        checkbox: [],
        bodyRequest: {},
        loading: true
    }),
    created: function(){
        axios
        .get(`/v1/subscription/agencies?url=${this.$route.params.agencySubDomain}.dispojob.pro`)
        .then((response: AxiosResponse) => {
            if (Object.keys(response.data).length !== 0) {
                this.agencies = response.data
                // If there is only one agency and a logo is defined we want to display the logo
                if (Object.keys(this.agencies).length === 1 && response.data[0].logo) {
                    this.updateLogo(response.data[0].logo)
                }
                // Otherwise we put by default the logo of dispojob
                else {
                    this.logo = require("@/assets/logos/dispojob_label.svg")
                }
            }
        })
        .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
        }).finally(() => {
            this.loading = false
        })
    },
    methods: {
        updateStepTitle(title:string) {
            this.stepTitle = title
        },
        nextStep(step:number) {
            this.step = step
        },
        updateLogo(src:string) {
            // NOTE - this is imperfect, currently in production it used the mounted /mnt/storage/uploads/agency/ folder in /img/logos/ for agency logos
            this.logo = ['development', 'test'].includes(process.env.NODE_ENV) ? require("@/assets/logos/" + src) : '/img/logos/' + src
        },
        displaySnackbarFromChild(message: string) {
            EventBus.$emit('snackbar',{message: message, color: 'error'})
        }
    }
})
