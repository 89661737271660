
import Vue from 'vue'

export default Vue.extend({
  name: 'SubscriptionConfirmation',
  methods: {
    refresh() {
      location.reload()
    }
  },
  props: {
    express: {
      default: false,
      type: Boolean
    }
  },
})
