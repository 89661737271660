
import axios from "axios"
import Vue from "vue"
import Webcam from "@/components/Webcam.vue"
import gRecaptcha from '@finpo/vue2-recaptcha-invisible'

interface FileDropEvent {
  dataTransfer : {
    files : File[]
  }
}

export default Vue.extend({
  name: "SingleUploadCard",
  components: {
    Webcam,
    gRecaptcha
  },
  props: {
    reference: String,
    name: String,
    kiosk: {
      default: false,
      type: Boolean
    },
    siteKey: {
      default: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_API_KEY,
      type: String
    },
    selectedSpace: {
      default: null,
      type: Object,
    },
    ocr: {
      default: 0,
      type: Number
    },
    express: {
      default: false,
      type: Boolean
    },
    consent:{
      default: false,
      type: Boolean
    },
    facingMode: {
      type: String,
      default: 'environment',
      validator(value: string) {
        return ['user', 'environment'].includes(value)
      }
    }
  },
  data: () => ({
    file: null as null | Blob | File,
    loading: false,
    icon: "mdi-plus-thick",
    color: "",
    uploading: false,
  }),
  methods: {
    validate(){
      return true
    },
    callback(token: string){
        if (token) {
          this.uploadFile()
        }
    },
    selectFile() {
      if (this.kiosk) { // trigger selection from webcam
        const webcamInput = (this.$refs.webcam as Vue & { openWebcam(): (() => void) })
        webcamInput.openWebcam()
      } else { // trigger selection from file upload
        const fileInput = this.$refs[this.$props.reference] as Vue & {
          $refs: {
            input: HTMLElement
          }
        }
        fileInput.$refs.input.click()
      }
    },
    uploadSnapshot(snapshot: Blob | null) {
      this.file = snapshot
      this.uploadFile()
    },
    uploadFile(event?: FileDropEvent) {
      if (event !== undefined) {
        this.file =  event.dataTransfer.files[0]
      }
      if(this.express){
        this.$emit("emitLoader")
      }
      if (this.file && this.file.size <= 8388608) {
        this.loading = true
        this.uploading = true // spinner
        let formData = new FormData()
        // file
        formData.append("files[]", this.file)
        // OCR Data
        if (this.$props.express){
          formData.append("ocr", this.selectedSpace.ocr)
          formData.append("url", this.$route.params.agencySubDomain)
          formData.append("spaceId", this.selectedSpace.id)
        }
        axios
        .post("/uploads", formData)
        .then((response) => {
          this.loading = false
          this.icon = "mdi-check-circle"
          this.color = "primary"
          let ocrResult
          if (response.data.resultOcr !== null && response.data.resultOcr !== undefined) {
            ocrResult = response.data.resultOcr[0]
          }
            this.$emit(
              "uploadFile",
              this.$props.reference,
              response.data.success[0],
              ocrResult
            )
          })
          .catch((error) => {
            this.loading = false            
            this.emitError(
              this.$vuetify.lang.t("$vuetify.api_errors." + error.response.status), 'error'
            )
          }).finally(() => {
            this.uploading = false // spinner
          })
      } else {
        this.emitError(
          this.$vuetify.lang.t(
            "$vuetify.subscription_page.non_compliant_file_error", 'error'
          )
        )
      }
    },
    emitError(message: string, color?: string) {      
      this.$emit("emitError", message, color)
    }
  },
})
