import { render, staticRenderFns } from "./SingleUploadCard.vue?vue&type=template&id=2a48f5ad&scoped=true"
import script from "./SingleUploadCard.vue?vue&type=script&lang=ts"
export * from "./SingleUploadCard.vue?vue&type=script&lang=ts"
import style0 from "./SingleUploadCard.vue?vue&type=style&index=0&id=2a48f5ad&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a48f5ad",
  null
  
)

export default component.exports