
import Vue from "vue"
import { state, mutations } from "@/plugins/state"
import * as importedLocales from "@/locale"

type ObjectKey = keyof typeof importedLocales

export default Vue.extend({
  props: {
    languages: {
        type: Array,
        default: () => ['fr', 'en'] as string[]
      }
  },
  data: () => ({
    locales:[],
  }),
  created: function() {
    // Loop through all the locales
    Object.keys(importedLocales).forEach(locale => {
      let localeImported = locale as ObjectKey
      // Only keep the locales corresponding to wanted languages
      if (this.languages.includes(importedLocales[localeImported].code)) {          
        this.$data.locales.push({value: importedLocales[localeImported].code , text: importedLocales[localeImported].locale})
      } 
    })
  },
  computed: {
    locale: {
      get: () => {
        return state.locale
      },
      set: (value) => {
        mutations.setLocale(value)
      }
    }
  },
  methods: {
    changeLocale(value: string) {
      this.locale = value
    }
  },
  watch: {
    locale: function(currentLocale) {
      this.$vuetify.lang.current = currentLocale
      mutations.setLocale(currentLocale)
    }
  }
})
